import React, { useState, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';

const PianoContainer = styled.div`
  color: #000;
  font-family: 'Courier New', monospace;
  font-size: 14px;
  line-height: 1.4;
  padding: 20px;
  overflow: hidden;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${props => props.backgroundColor};
  padding-top: 60px; // Add padding to account for the navigation bar
`;

const Piano = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  user-select: none;
  position: relative;
  height: 130px;
  width: 280px;
`;

const Key = styled.div`
  cursor: pointer;
  transition: background-color 0.1s;
  box-sizing: border-box;
  position: absolute;
`;

const WhiteKey = styled(Key)`
  width: 40px;
  height: 120px;
  border: 1px solid #000;
  background-color: #fff;
  z-index: 1;
  &:hover, &.active {
    background-color: #ddd;
  }
`;

const BlackKey = styled(Key)`
  width: 24px;
  height: 80px;
  background-color: #000;
  z-index: 2;
  &:hover, &.active {
    background-color: #333;
  }
`;

const AnimatedText = styled.div`
  font-size: 18px;
  margin-top: 20px;
  height: 24px;
`;

const Firework = styled.div`
  position: fixed;
  pointer-events: none;
  z-index: 9999;
`;

const PianoWebsite = () => {
  const [backgroundColor, setBackgroundColor] = useState('#F0F8FF');
  const [animatedText, setAnimatedText] = useState('');
  const [fireworks, setFireworks] = useState([]);
  const animationRef = useRef(false);

  const characters = '!"#$%&\'()*+,-./0123456789:;<=>?@ABCDEFGHIJKLMNOPQRSTUVWXYZ[\\]^_`abcdefghijklmnopqrstuvwxyz{|}~';
  const backgroundColors = [
    '#F0F8FF', '#FAEBD7', '#00FFFF', '#7FFFD4', '#F0FFFF', '#F5F5DC', '#FFE4C4', 
    '#FFEBCD', '#FFF8DC', '#FFFACD', '#FFF5EE', '#F0FFF0', '#E6E6FA', '#FFF0F5', 
    '#F0E68C', '#E0FFFF', '#B0E0E6', '#FFE4E1', '#FFF5EE', '#F5FFFA', '#FFFAF0'
  ];

  const getRandomColor = () => `hsl(${Math.random() * 360}, 100%, 50%)`;
  const getRandomBackgroundColor = () => backgroundColors[Math.floor(Math.random() * backgroundColors.length)];

  const changeBackgroundColor = useCallback(() => {
    setBackgroundColor(getRandomBackgroundColor());
  }, []);

  const createFireworks = useCallback((x, y) => {
    const newFireworks = [];
    for (let i = 0; i < 50; i++) {
      const angle = Math.random() * Math.PI * 2;
      const speed = Math.random() * 5 + 2;
      newFireworks.push({
        id: Date.now() + i,
        x,
        y,
        char: characters[Math.floor(Math.random() * characters.length)],
        color: getRandomColor(),
        angle,
        speed,
        posX: 0,
        posY: 0,
      });
    }
    setFireworks(prev => [...prev, ...newFireworks]);
  }, [characters]);

  useEffect(() => {
    const animateFireworks = () => {
      setFireworks(prev => prev.filter(fw => {
        fw.posX += Math.cos(fw.angle) * fw.speed;
        fw.posY += Math.sin(fw.angle) * fw.speed;
        return (
          fw.x + fw.posX >= 0 &&
          fw.x + fw.posX <= window.innerWidth &&
          fw.y + fw.posY >= 0 &&
          fw.y + fw.posY <= window.innerHeight
        );
      }));
    };

    const interval = setInterval(animateFireworks, 16);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const typeText = async (text, delay = 100) => {
      for (let i = 0; i < text.length; i++) {
        await new Promise(resolve => setTimeout(resolve, delay));
        setAnimatedText(prev => prev + text[i]);
      }
    };

    const backspace = async (count, delay = 50) => {
      for (let i = 0; i < count; i++) {
        await new Promise(resolve => setTimeout(resolve, delay));
        setAnimatedText(prev => prev.slice(0, -1));
      }
    };

    const animateText = async () => {
      if (animationRef.current) return;
      animationRef.current = true;

      await typeText("Website under contruction.");
      await new Promise(resolve => setTimeout(resolve, 1000));
      await backspace(10);
      await typeText("construction.");
    };

    animateText();
  }, []);

  const handleKeyPress = useCallback((e) => {
    e.target.classList.add('active');
    createFireworks(e.clientX, e.clientY);
    changeBackgroundColor();
  }, [createFireworks, changeBackgroundColor]);

  const handleKeyRelease = useCallback((e) => {
    e.target.classList.remove('active');
  }, []);

  return (
    <PianoContainer backgroundColor={backgroundColor}>
      <h1>Interactive Piano</h1>
      <Piano>
        <WhiteKey style={{ left: '0px' }} onMouseDown={handleKeyPress} onMouseUp={handleKeyRelease} onMouseLeave={handleKeyRelease} />
        <BlackKey style={{ left: '28px' }} onMouseDown={handleKeyPress} onMouseUp={handleKeyRelease} onMouseLeave={handleKeyRelease} />
        <WhiteKey style={{ left: '40px' }} onMouseDown={handleKeyPress} onMouseUp={handleKeyRelease} onMouseLeave={handleKeyRelease} />
        <BlackKey style={{ left: '68px' }} onMouseDown={handleKeyPress} onMouseUp={handleKeyRelease} onMouseLeave={handleKeyRelease} />
        <WhiteKey style={{ left: '80px' }} onMouseDown={handleKeyPress} onMouseUp={handleKeyRelease} onMouseLeave={handleKeyRelease} />
        <WhiteKey style={{ left: '120px' }} onMouseDown={handleKeyPress} onMouseUp={handleKeyRelease} onMouseLeave={handleKeyRelease} />
        <BlackKey style={{ left: '148px' }} onMouseDown={handleKeyPress} onMouseUp={handleKeyRelease} onMouseLeave={handleKeyRelease} />
        <WhiteKey style={{ left: '160px' }} onMouseDown={handleKeyPress} onMouseUp={handleKeyRelease} onMouseLeave={handleKeyRelease} />
        <BlackKey style={{ left: '188px' }} onMouseDown={handleKeyPress} onMouseUp={handleKeyRelease} onMouseLeave={handleKeyRelease} />
        <WhiteKey style={{ left: '200px' }} onMouseDown={handleKeyPress} onMouseUp={handleKeyRelease} onMouseLeave={handleKeyRelease} />
        <BlackKey style={{ left: '228px' }} onMouseDown={handleKeyPress} onMouseUp={handleKeyRelease} onMouseLeave={handleKeyRelease} />
        <WhiteKey style={{ left: '240px' }} onMouseDown={handleKeyPress} onMouseUp={handleKeyRelease} onMouseLeave={handleKeyRelease} />
      </Piano>
      <AnimatedText>{animatedText}</AnimatedText>
      {fireworks.map(fw => (
        <Firework
          key={fw.id}
          style={{
            left: `${fw.x}px`,
            top: `${fw.y}px`,
            transform: `translate(${fw.posX}px, ${fw.posY}px)`,
            color: fw.color,
          }}
        >
          {fw.char}
        </Firework>
      ))}
    </PianoContainer>
  );
};

export default PianoWebsite;