import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import styled from 'styled-components';
import PianoWebsite from './PianoWebsite';
import Navigation from './Navigation';
import AnimatedLayout from './AnimatedLayout';
import ContactForm from './ContactForm';

const AppContainer = styled.div`
  position: relative;
  min-height: 100vh;
`;

const AboutPage = () => <h1>About Page</h1>;

function App() {
  return (
    <Router>
      <AppContainer>
        <Navigation />
        <AnimatedLayout>
          <Routes>
            <Route path="/" element={<PianoWebsite />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/contact" element={<ContactForm />} />
          </Routes>
        </AnimatedLayout>
      </AppContainer>
    </Router>
  );
}

export default App;